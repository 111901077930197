import { graphql } from "gatsby";
import React from "react";

import { useBlogCategory } from "../GraphQl/useBlogCategory";
import Layout from "../components/layout";
import { BlogTab } from "./blogTab";

const BlogPortal = ({ pageContext, data: { posts } }) => {
  const categories = useBlogCategory();

  return (
    <Layout>
      <BlogTab
        categories={categories}
        posts={posts}
        pageContext={pageContext}
      />
    </Layout>
  );
};

export default BlogPortal;

export const pageQuery = graphql`
  query blogPortalQuery($skip: Int!, $limit: Int!) {
    posts: allSanityNewsArticle(skip: $skip, limit: $limit) {
      nodes {
        id
        heading
        author
        date
        content: _rawContent(resolveReferences: { maxDepth: 100 })
        slug {
          current
        }
        _rawImage
        category {
          slug {
            current
          }
        }
        meta {
          _key
          _type
          metaTitle
          metaDescription
          _rawMetaImage
        }
      }
    }
  }
`;
